<template>
  <el-main>
    <el-tabs v-model="activeName">
      <el-tab-pane label="权益价格" name="1">
        <div class="tips">
          *权益分类：{{
            type == 0
              ? '只适用于城市合伙人购买权限'
              : type == 1
              ? '只适用于市场合伙人申请身份时购买，只要购买则一直都有此类目的订阅权益，市场合伙人需要购买分类才可以成为合伙人'
              : type == 2
              ? '只适用于市场合伙人申请身份购买，只要购买则一直都有此类目的用户数据查阅权益'
              : type == 3 || type == 4
              ? '只适用于市场合伙人申请身份购买，只要购买则一直都有免费下载此分类权限'
              : '只适用于市场合伙人和产品合伙人，购买身份时，勾选此分类价格'
          }}
        </div>
        <template v-if="type">
          <el-table :data="info.settings" border :header-cell-style="{ 'background-color': '#F8F9FA' }" style="width: 650px">
            <el-table-column
              prop="name"
              :label="
                type == 1
                  ? '*时价分类（只展示三级类目）'
                  : type == 2
                  ? '*CRM管理分类'
                  : type == 3
                  ? '*应用数据（市场数据）'
                  : type == 4
                  ? '*应用数据（物性表）'
                  : type == 5
                  ? '*商品分类（属于PE制品二级分类）'
                  : type == 6
                  ? '*商品分类（属于生物塑料二级分类）'
                  : '*商品分类（属于特种塑料二级分类）'
              "
              align="left"
            ></el-table-column>
            <el-table-column prop="area_price" label="*权益价格" align="left" width="300px">
              <template v-slot="{ row }">
                <el-input v-model="row.price">
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          <el-table :data="info.settings" border :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column prop="name" label="*所属区域" align="left">
              <template v-slot="{ row }">
                <el-cascader
                  v-if="row.area_type == 'city'"
                  v-model="row.area"
                  :props="{ value: 'id', label: 'name', children: '_child' }"
                  placeholder="请选择或搜索"
                  clearable
                  filterable
                  :options="city"
                ></el-cascader>
                <el-cascader v-else v-model="row.area" :props="{ value: 'id', label: 'name', children: '_child' }" placeholder="请选择或搜索" clearable filterable :options="district"></el-cascader>
              </template>
            </el-table-column>
            <el-table-column prop="area_price" label="*区域权限价格" align="left" width="300px">
              <template v-slot="{ row }">
                <el-input v-model="row.area_price">
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="limit_num" label="限制申请人数（不添加表示不限制人数，限制人数表示每个区域限制的人数）" align="left" width="300px">
              <template v-slot="{ row }">
                <el-input v-model="row.limit_num">
                  <template slot="append">人</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="150px">
              <template v-slot="{ $index }">
                <el-button v-if="$index" type="text" @click="info.settings.splice($index, 1)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="area-add">
            <el-button icon="el-icon-plus" @click="addArea(1)">添加地级市</el-button>
            <el-button icon="el-icon-plus" @click="addArea(0)">添加区/县</el-button>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane label="购买折扣" name="2">
        <div class="tips">*购买折扣：可以设置购买多个情况下</div>
        <div class="discount">
          <div class="discount-item" v-for="(item, index) in info.discount_settings" :key="index">
            <el-input class="discount-num" v-model="item.num"></el-input>
            <span>个，折扣</span>
            <el-input class="discount-proportion" type="number" min="0" max="9.9" v-model="item.discount"></el-input>
            <i v-if="index" class="el-icon-circle-close" @click="info.discount_settings.splice(index, 1)"></i>
          </div>
          <div class="discount-add">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="addDiscount">添加</el-button>
            最后添加的一个，表示此数量及超过此数量，折扣都为此折扣
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <Preservation @preservation="preservation">
      <router-link to="/member/partnersEquity" slot="return" style="margin-right: 10px">
        <el-button>返回</el-button>
      </router-link>
    </Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import _ from 'lodash';
export default {
  components: {
    Preservation,
  },
  data() {
    return {
      type: '',
      activeName: '1',
      info: {},
      city: [],
      district: [],
    };
  },
  created() {
    this.type = Number(this.$route.query.type);
    this.initData();
  },
  methods: {
    async initData() {
      if(!this.type) await this.getArea();
      await this.getInfo();
    },
    getInfo() {
      this.$axios.post(this.$api.gateway.member.depositInfo, { type: this.type }).then(res => {
        if (res.code == 0) {
          let info = res.result;
          let city = this.city;
          let district = this.district;
          if(!info.settings){
            this.$axios.post(info.type == 8 || info.type == 9 || info.type == 10 ? this.$api.gateway.member.secondLevelList2 :  this.$api.gateway.member.secondLevelList,{
              type:info.type
            }).then(res=>{
              if(res.code == 0){
                let list = res.result;
                list.map(item=>{
                  item.price = '';
                  item.type = info.type
                })
                this.info.settings = list;
              }
            })
          }
          for (let i in info.settings) {
            if (info.settings[i].area_type == 'city') {
              for (let y in city) {
                for (let k in city[y]._child) {
                  if (city[y]._child[k].id == info.settings[i].area) info.settings[i].area = [city[y].id, city[y]._child[k].id];
                }
              }
            } else {
              for (let y in district) {
                for (let k in district[y]._child) {
                  for (let b in district[y]._child[k]._child) {
                    if (district[y]._child[k]._child[b].id == info.settings[i].area) info.settings[i].area = [district[y].id, district[y]._child[k].id, district[y]._child[k]._child[b].id];
                  }
                }
              }
            }
          }
          this.info = res.result;
        }
      });
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          let city = _.cloneDeep(res.result.list);
          for (let i in city) {
            for (let y in city[i]._child) delete city[i]._child[y]._child;
          }
          this.city = city;
          this.district = res.result.list;
        }
      });
    },
    addArea(type) {
      this.info.settings.push({
        area: '',
        area_price: '',
        area_type: type ? 'city' : 'district',
        limit_num: '',
      });
    },
    addDiscount() {
      this.info.discount_settings.push({
        discount: '',
        num: '',
      });
    },
    preservation() {
      let info = this.info;
      let data = {
        type: info.type,
        name: info.name,
      };
      for (let i in info.settings) {
        for (let y in info.settings[i]) {
          if (!info.settings[i][y]) return this.$message.warning('请将权益价格信息填写完整');
        }
      }
      for (let i in info.discount_settings) {
        for (let y in info.discount_settings[i]) {
          if (!info.discount_settings[i][y]) return this.$message.warning('请将权购买折扣信息填写完整');
          if(y == 'discount' && (info.discount_settings[i][y] > 9.9 || info.discount_settings[i][y] < 0.1)){
            return this.$message.warning("请填写0.1-9.9之间折扣");
          }
        }
      }
      data.setting = _.cloneDeep(info.settings);
      if (!this.type) for (let i in data.setting) data.setting[i].area = data.setting[i].area.slice(-1)[0];
      data.discount_setting = info.discount_settings;
      this.$axios.post(this.$api.gateway.member.depositAdd, data).then(res => {
        if (res.code == 0) {
          this.$message.success('保存成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  height: 100%;
  padding-bottom: 100px;
  .tips {
    margin-bottom: 20px;
  }
  .discount {
    .discount-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .discount-num {
        width: 300px;
        margin-right: 10px;
      }
      .discount-proportion {
        width: 200px;
        margin: 0 10px;
      }
      i {
        margin-left: 10px;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
  .el-cascader {
    width: 500px;
  }
  .area-add {
    margin-top: 15px;
  }
}
</style>