var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"权益价格","name":"1"}},[_c('div',{staticClass:"tips"},[_vm._v(" *权益分类："+_vm._s(_vm.type == 0 ? '只适用于城市合伙人购买权限' : _vm.type == 1 ? '只适用于市场合伙人申请身份时购买，只要购买则一直都有此类目的订阅权益，市场合伙人需要购买分类才可以成为合伙人' : _vm.type == 2 ? '只适用于市场合伙人申请身份购买，只要购买则一直都有此类目的用户数据查阅权益' : _vm.type == 3 || _vm.type == 4 ? '只适用于市场合伙人申请身份购买，只要购买则一直都有免费下载此分类权限' : '只适用于市场合伙人和产品合伙人，购买身份时，勾选此分类价格')+" ")]),(_vm.type)?[_c('el-table',{staticStyle:{"width":"650px"},attrs:{"data":_vm.info.settings,"border":"","header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('el-table-column',{attrs:{"prop":"name","label":_vm.type == 1
                ? '*时价分类（只展示三级类目）'
                : _vm.type == 2
                ? '*CRM管理分类'
                : _vm.type == 3
                ? '*应用数据（市场数据）'
                : _vm.type == 4
                ? '*应用数据（物性表）'
                : _vm.type == 5
                ? '*商品分类（属于PE制品二级分类）'
                : _vm.type == 6
                ? '*商品分类（属于生物塑料二级分类）'
                : '*商品分类（属于特种塑料二级分类）',"align":"left"}}),_c('el-table-column',{attrs:{"prop":"area_price","label":"*权益价格","align":"left","width":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('el-input',{model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)]}}],null,false,2160107780)})],1)]:[_c('el-table',{attrs:{"data":_vm.info.settings,"border":"","header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('el-table-column',{attrs:{"prop":"name","label":"*所属区域","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [(row.area_type == 'city')?_c('el-cascader',{attrs:{"props":{ value: 'id', label: 'name', children: '_child' },"placeholder":"请选择或搜索","clearable":"","filterable":"","options":_vm.city},model:{value:(row.area),callback:function ($$v) {_vm.$set(row, "area", $$v)},expression:"row.area"}}):_c('el-cascader',{attrs:{"props":{ value: 'id', label: 'name', children: '_child' },"placeholder":"请选择或搜索","clearable":"","filterable":"","options":_vm.district},model:{value:(row.area),callback:function ($$v) {_vm.$set(row, "area", $$v)},expression:"row.area"}})]}}])}),_c('el-table-column',{attrs:{"prop":"area_price","label":"*区域权限价格","align":"left","width":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('el-input',{model:{value:(row.area_price),callback:function ($$v) {_vm.$set(row, "area_price", $$v)},expression:"row.area_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)]}}])}),_c('el-table-column',{attrs:{"prop":"limit_num","label":"限制申请人数（不添加表示不限制人数，限制人数表示每个区域限制的人数）","align":"left","width":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('el-input',{model:{value:(row.limit_num),callback:function ($$v) {_vm.$set(row, "limit_num", $$v)},expression:"row.limit_num"}},[_c('template',{slot:"append"},[_vm._v("人")])],2)]}}])}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","align":"center","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var $index = ref.$index;
return [($index)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.info.settings.splice($index, 1)}}},[_vm._v("删除")]):_vm._e()]}}])})],1),_c('div',{staticClass:"area-add"},[_c('el-button',{attrs:{"icon":"el-icon-plus"},on:{"click":function($event){return _vm.addArea(1)}}},[_vm._v("添加地级市")]),_c('el-button',{attrs:{"icon":"el-icon-plus"},on:{"click":function($event){return _vm.addArea(0)}}},[_vm._v("添加区/县")])],1)]],2),_c('el-tab-pane',{attrs:{"label":"购买折扣","name":"2"}},[_c('div',{staticClass:"tips"},[_vm._v("*购买折扣：可以设置购买多个情况下")]),_c('div',{staticClass:"discount"},[_vm._l((_vm.info.discount_settings),function(item,index){return _c('div',{key:index,staticClass:"discount-item"},[_c('el-input',{staticClass:"discount-num",model:{value:(item.num),callback:function ($$v) {_vm.$set(item, "num", $$v)},expression:"item.num"}}),_c('span',[_vm._v("个，折扣")]),_c('el-input',{staticClass:"discount-proportion",attrs:{"type":"number","min":"0","max":"9.9"},model:{value:(item.discount),callback:function ($$v) {_vm.$set(item, "discount", $$v)},expression:"item.discount"}}),(index)?_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){return _vm.info.discount_settings.splice(index, 1)}}}):_vm._e()],1)}),_c('div',{staticClass:"discount-add"},[_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":_vm.addDiscount}},[_vm._v("添加")]),_vm._v(" 最后添加的一个，表示此数量及超过此数量，折扣都为此折扣 ")],1)],2)])],1),_c('Preservation',{on:{"preservation":_vm.preservation}},[_c('router-link',{staticStyle:{"margin-right":"10px"},attrs:{"slot":"return","to":"/member/partnersEquity"},slot:"return"},[_c('el-button',[_vm._v("返回")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }